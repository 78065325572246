<template>


  <div v-if="!is_load">

    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
       
        <router-link to="/admin/business/lists"> Quản lý Doanh Nghiệp vận tải</router-link>
      </li>

      <li class="breadcrumb-item">Doanh Nghiệp vận tải</li>
    </ol>
     <FormValidate v-slot="{ invalid ,reset }">

    <form   @submit.prevent="onSubmit" @reset.prevent="reset" id="sendform">
      
      <div class="container">
        <ul class="nav nav-tabs" role="tablist"  >
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">

      

          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin chung </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                     

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            > Tên DN <span class="text-danger">*</span></label
                          >
                          
                            
                           <InputValidate name="Tên Doanh Nghiệp " rules="required" v-slot="{ errors }">
                              <input v-model="Form.name" type="text" name="name"  class="form-control" />
                              <span class='text text-danger'>{{ errors[0] }}</span>
                            </InputValidate>
                          
                        </div>
                      </div>

                       <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Mã số thuế   <span class="text-danger">*</span></label
                          >
                          
                            
                           <InputValidate name="Mã số " rules="required" v-slot="{ errors }">
                              <input v-model="Form.code" type="text" name="code"  class="form-control" />
                              <span class='text text-danger'>{{ errors[0] }}</span>
                            </InputValidate>
                          
                        </div>
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="picture"
                            >Hình ảnh đại diện<span class="text-danger"
                              >*</span
                            ></label
                          >
                            <InputValidate name="Hình ảnh đại diện" rules="required|image" v-slot="{ errors }">
                                  <input
                                    type="file"
                                    accept="image/*"
                                    name="picturefile"
                                    class="form-control main_picture"
                                    @change="onPicturefile"
                                  />

                                  <div class="view_picture_one" v-if="img_one != ''">
                                    <img :src="img_one" style="max-width: 400px" />
                                  </div>
                                    <span class='text text-danger'>{{ errors[0] }}</span>
                          </InputValidate>
                        </div>
                      </div>


                       <div class="col-sm-10">
                       
                        <div class="form-group" v-if="location!=null">
                          <label for="name"
                            >Tỉnh/Thành phố   <span class="text-danger">*</span></label
                          >
                        

                           <InputValidate name="Tỉnh/Thành phố" rules="required" v-slot="{ errors }">
                            
                              <select name="codeLocation" v-model="Form.codeLocation" class="form-control" >
                                 <option  value=""> Tuỳ chọn </option>
                                 <option v-for="(v,k) in location" :key="k" :value="v.id">{{v.name}}</option>
                              </select>
                             
                              <span class='text text-danger'>{{ errors[0] }}</span>
                            </InputValidate>
                                               
                            
                        </div>
                       
                      </div>


                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            > Địa chỉ </label
                          >                          
                              <input v-model="Form.address" type="text" name="address"  class="form-control" />

                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            > Quầy vé  </label
                          >                          
                              <input v-model="Form.ticketCounter" type="text" name="ticketCounter"  class="form-control" />

                        </div>
                      </div>
                        <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            > Email </label
                          >                          
                              <input v-model="Form.email" type="text" name="email"  class="form-control" />

                        </div>
                      </div>
                       <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Website </label
                          >                          
                              <input v-model="Form.website" type="text" name="website"  class="form-control" />

                        </div>
                      </div>
                      
                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            > Số điện thoại  </label
                          >                          
                              <input v-model="Form.phone" type="text" name="phone"  class="form-control" />

                        </div>
                      </div>

                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Fax </label
                          >                          
                              <input v-model="Form.fax" type="text" name="fax"  class="form-control" />

                        </div>
                      </div>

                       




                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
                    <div class="row">
                     

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            > Name <span class="text-danger">*</span></label
                          >
                          
                            
                           <InputValidate name="Tên Doanh Nghiệp " rules="required" v-slot="{ errors }">
                              <input v-model="Form.name_en" type="text" name="name_en"  class="form-control" />
                              <span class='text text-danger'>{{ errors[0] }}</span>
                            </InputValidate>
                          
                        </div>
                      </div>

                      


                      <div class="col-sm-5">
                        <div class="form-group">
                          <label for="name"
                            >  Address </label
                          >                          
                              <input v-model="Form.address_en" type="text" name="address_en"  class="form-control" />

                        </div>
                      </div>

                     
                      
                       




                    </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
             
            >
              <i class="fa fa-save"></i> Save
            </button>
             <button
              type="reset"
              class="btn btn-sm btn-warning ml-3"
              @click="myreset"
               :disabled="invalid"
            >
              <i class="fa fa-save"></i> Reset
            </button>
            
             
    
           
          </div>
        </div>
      </div>
    </form>
  </FormValidate>
  </div>
  <div v-else>
        <MyLoadTheme>
             
               
            <div class="container">
        
        <div class="tab-content">

      

          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                
                  <div class="card-body">
                    <div class="row">
                     

                      <div class="col-sm-10"  v-for="k in 10" :key="k">
                        <div class="form-group">
                           <MyLoading></MyLoading>
                        </div>
                      </div>

                       

                      
                       




                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
         
        </div>
      </div>
        </MyLoadTheme>
  </div>
</template>

<script>
import axios from "axios";

  
export default {
  data() {
    return {
      is_load: false,
      img_one: "",
      my_url: window.my_url,
     
      list_cate: null,
      isLogin: null,
      Form: {
          id:"",
          name:'',
          code:"",
          codeLocation:"",
          address:"",
          phone:"",
          email:"",
          website:"",
          fax:"",
          ticketCounter:"",
          picture:"",
          

          name_en:"",
          address_en:''


      },
      location:null

     
     
    };
  },
  created() {
    let isLogin = this.$session.get("isLogin");
    this.isLogin = isLogin.permistions.split(",");
    
  },
  
  mounted() {
        
         var p1 = axios.get(window.my_api + "api/location/get-locations", {
            headers: window.my_header
          })
          .then((res) => {
            if (res.status == 200) {
              this.location  = res.data.data;
            
            } else {
              alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
            }
          });

           var p2 = axios.get(window.my_api + "api/business/get-business-by-id", {
              params:{businessId:this.$route.params.id},
              headers: window.my_header
            })
              .then((res) => {
                if (res.status == 200) {
                  this.Form  = res.data.data;
                  this.img_one= this.my_url+this.Form.picture;
                } else {
                  alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                }
              });

     var p3 = axios.get(window.my_api + "api/business/get-business-by-id", {
              params:{businessId:this.$route.params.id},
              headers: {
                 language:"en"
              }
            })
              .then((res) => {
                if (res.status == 200) {
                  this.Form.name_en = res.data.data.name;
                   this.Form.address_en = res.data.data.address;
                  
                } else {
                  alert("Hệ thống không hoạt động, vui lòng liên hệ với Admin.");
                }
              });

          this.is_load=true;
          Promise.all([p1,p2,p3]).then(()=>{
              this.is_load=false;
          } );   
  },
  methods: {
    
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
    myreset(){
        
                 Object.keys(this.Form).forEach(v => {
                      this.Form[v]="";
                  });
    },
    onSubmit: function () {
        let myForm = document.getElementById("sendform");

       let formData = new FormData(myForm);
      
       
      formData.append("id",this.Form.id);  
       formData.append("code_en",this.Form.code);  
       formData.append("codelocation_en",this.Form.codelocation);
       formData.append("phone_en",this.Form.phone);
       formData.append("email_en",this.Form.email);
       formData.append("website_en",this.Form.website);
       formData.append("ticketcounter_en",this.Form.ticketCounter);


      
        if(formData.get('picturefile').size==0){
           formData.append("picture",this.Form.picture);
         }
       
        

     
       
         
        
        axios
          .post(window.my_api + "api/business/create-or-update-business", formData, {
            headers: window.my_header,
          })
          .then((res) => {

            if (res.status == 200) {
               if(res.data.successed){
                    alert("Cập nhật thành công.");
                      
                     
               }else{
                      alert("Vui lòng kiểm tra lại dữ liệu");
                      
                     
               }
            

            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
             
            }
             this.is_load = false;
          });
      
    },
  },
};
</script>